import styled from 'src/style';
import { mediaQueries } from 'src/style/breakpoints';

export const SectionHeaderBlock = styled.div({
  textAlign: 'center',
  maxWidth: 730,
  display: 'flex',
  paddingBottom: 50,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [mediaQueries.phoneOnly]: {
    paddingBottom: 20,
  },
});
